import React, { FC } from 'react';
import {Wrapper} from '../../components/Wrapper';
import styles from './Footer.module.scss';

export const Footer: FC = () => {
    return (
      <footer style={{
				width: '100%',
				height: '180px',
				background: 'rgb(244, 247, 251)'
			}}>
				<Wrapper style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '16px 0 0 0'
						// alignItems: 'center',
					}}>
					<div style={{flex: 1}}>
						<h5 className={styles.footer_title}>eWay</h5>
						<a className={styles.footer_link} href='/'>О сервисе</a>
						<a className={styles.footer_link} href='/'>Трекинг</a>
						<a className={styles.footer_link} href='/'>Кэшбэк за чеки</a>
						<a className={styles.footer_link} href='/'>Промокоды</a>
					</div>
					<div style={{flex: 1}}>
						<h5 className={styles.footer_title}>Кэшбэк-сервис</h5>
						<a className={styles.footer_link} href='/'>Кэшбэк</a>

						<a className={styles.footer_link} href='/'>Магазины</a>
						<a className={styles.footer_link} href='/'>Привести друга</a>
						<a className={styles.footer_link} href='/'>Повышенный кэшбэк</a>
					</div>
					<div style={{flex: 1}}>
						<h5 className={styles.footer_title}>Заботимся о вас</h5>
						<a className={styles.footer_link} href='/'>eWay блог</a>
						<a className={styles.footer_link} href='/'>Техподдержка</a>
						<a className={styles.footer_link} href='/'>Вопросы и ответы</a>
					</div>
					<div style={{flex: 1}}>
						<h5 className={styles.footer_title}>Соцсети</h5>

					</div>
				</Wrapper>
			</footer>
    )
}