import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './pages/Layout';
import { HomePage } from './pages/home-page';
import { AuthPage } from './pages/auth-page';
import { NotFaundPage } from './pages/not-faund-page';

const App: FC<any> = () => {
	return (
		<>
			<Routes>
				<Route path={'/'} element={<Layout />}>
					<Route index element={<HomePage />} />
					<Route path={'auth'} element={<AuthPage />} />
					<Route path={'*'} element={<NotFaundPage />} />
				</Route>
			</Routes>
		</>
	);
};

export default App;
