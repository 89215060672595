import React, {FC} from 'react';
import { Wrapper } from '../Wrapper';
import plusBtn from '../../assets/icon/plusBtn.svg';

export const HeaderRow: FC <any> = () => {

  const tel = {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '17px',
    color: '#282739',
  }

  const btn = {
    margin: 0,
    padding: 0,
    width: '18px',
    height: '18px',
    background: 'none',
    border: 'none',
    outline: 'none',
    marginLeft: '9px'
  }

  const NavLik = {
    marginRight: '30px', 
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '17px',
    color: '#5D6C7B',
    textDecoration: 'none'
  };
  
  
  return(
    <Wrapper style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <div>
        <a style={NavLik} href="#">Сервис</a>
        <a style={NavLik} href="#">Сотрудничество</a>
        <a style={NavLik} href="#">Заказать звонок</a>
      </div>
      <div style={{  display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <div style={tel}>+7 (800) 505-54-61</div>
        {/* <button style={btn}>
          <img src={plusBtn} alt="+" />
        </button> */}
      </div>
    </Wrapper>
  );
}