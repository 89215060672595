import React, { FC, useCallback } from 'react';

export const Button: FC<any> = (data, onClick) => {

  const Btn = useCallback((btn: any) => {
    // console.log("DATA: ", btn)
    switch(btn?.data?.type) {
      case 'menuBtn': {
        return (
          <button style={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '23px',
            height: '40px',
            color: '#FFFFFF',
            padding: '8px 18px',
            background: '#6F73EE',
            border: '1.5px solid #6F73EE',
            borderRadius: '1px',
            cursor: "pointer"
          }}>
            {btn?.data?.name}
          </button>
        )
      }
      default: 
        return ( 
          <button style={{
            minWidth: '132px',
            border: 'none',
            outline: 'none',
            background: 'none',
            width: '26%',
            cursor: 'pointer'
          }}>
            {btn?.data?.name}
        </button>
       )
      
    }
  }, [data])

  return (
    <>
    {
      Btn(data)
    }
    </>
  )
}