import React, { FC } from 'react';
import { HeaderRow } from '../HeaderRow';
import { Wrapper } from '../Wrapper';
import { Link } from 'react-router-dom';
import { Search } from '../../common/Search';
import { Button } from '../Button';
import styles from './Navigation.module.scss';

export const Navigation: FC<any> = () => {
	return (
		<header>
			<div style={{ height: '36px', width: '100%', borderBottom: '1px solid #ECF3FF' }}>
				<HeaderRow />
			</div>
			<div
				style={{
					padding: '27px 0 34px 0',
				}}>
				<Wrapper style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start' 
					}}>
					<a
						href={'/'}
						style={{
							fontWeight: 700,
							fontSize: '30px',
							lineHeight: '43px',
							color: '#282739',
							cursor: 'pointer',
							textDecoration: 'none',
							marginRight: '35px',
						}}>
						eWAY
					</a>	
					<Button
						data={{
							type: 'menuBtn',
							name: 'Каталог',
						}}
					/>

					<div style={{ width: '100%', margin: '0 47px' }}>
						<Search />
					</div>

					<Button
						data={{
							type: 'any',
							name: 'Вход / Регистрация',
						}}
					/>
				</Wrapper>
			</div>
			<div
				style={{
					background: '#F4F7FB',
					height: '40px',
				}}>
				<Wrapper  style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<Link className={styles.link_footer_navigation} to='/'>Универмаг</Link>
					<Link className={styles.link_footer_navigation} to='/'>Продукты</Link>
					<Link className={styles.link_footer_navigation} to='/'>Аптека</Link >
					<Link className={styles.link_footer_navigation} to='/'>Дом</Link >
					<Link className={styles.link_footer_navigation} to='/'>Спорт</Link >
					<Link className={styles.link_footer_navigation} to='/'>Одежда</Link >
					<Link className={styles.link_footer_navigation} to='/'>Электронника</Link >
					<Link className={styles.link_footer_navigation} to='/'>Детям</Link >
					<Link className={styles.link_footer_navigation} to='/'>Красота</Link >
				</Wrapper>
			</div>
		</header>
	);
};
