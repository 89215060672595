import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '../../components/Footer';
import { Navigation } from '../../components/Navigation';
import { Wrapper } from '../../components/Wrapper';

export const Layout: FC = () => {
	return (
		<>
			<Navigation />
			<main style={{
				width: '100%',
				height: 'calc(100vh - 360px)',
				padding: '16px 0'
			}}>
				<Wrapper>
					<Outlet />
				</Wrapper>
			</main>
			<Footer />
		</>
	);
};
