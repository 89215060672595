import React, { FC } from 'react';

export const Search: FC = () => {
	return (
		<div
			style={{
				width: '100%',
			}}>
			<input
				style={{
					minWidth: '100%',
					height: '40px',
					padding: '0 20px',
					outline: 'none',
					border: '1.5px solid #6F73EE',
					borderRadius: '1px',
				}}
			/>
		</div>
	);
};
